import React from "react";
import {
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
} from "@chakra-ui/react";
import { Link } from "@reach/router";

export default function AlertLocked({ boughtFor }) {
  const lockedNum = boughtFor.reduce((acc, user) => {
    if (user.status === 2) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return (
    <>
      {lockedNum > 0 && (
        <Alert
          status="warning"
          variant="top-accent"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          position="sticky"
          bottom="0"
          padding="1rem"
          mt="10"
        >
          <AlertIcon />
          <AlertTitle fontSize="s" textAlign="center" mt={4} mb={1}>
            You have locked {lockedNum} people.
          </AlertTitle>
          <AlertDescription textAlign="center">
            Either remove them from your Bought For table on your{" "}
            <Text as="span" color="blue.500" textDecoration="underline">
              <Link to="/dashboard">dashboard</Link>
            </Text>
            , or go to your{" "}
            <Text as="span" color="blue.500" textDecoration="underline">
              <Link to="/book" color="blue.500">
                booking page
              </Link>
            </Text>
            .
          </AlertDescription>
        </Alert>
      )}
    </>
  );
}
