import React, { useState, } from "react";

import RankingTable from "./RankingTable";



import { EditableCard } from "../common/Card";

function Rankings() {
  const [isEditing, setIsEditing] = useState(false);



  function toggleEdit() {

    setIsEditing(!isEditing);
  }

  return (
    <EditableCard
      heading="Priority List"
      onEditClick={toggleEdit}
      label="Edit your priority list"
    >
      <RankingTable isEditing={isEditing} />
    </EditableCard>
  );
}

export default Rankings;
