import React, { useState, useContext, useEffect, useCallback } from "react";

import { findUsers, deleteUser, updateUserStatusAdmin } from "../api";

import { UserContext } from "../context";
import {
    Box, Heading, Flex, Spinner, Checkbox, Stack, Select, Text, Button, Popover,
    PopoverTrigger, PopoverContent, PopoverBody, Radio, RadioGroup
} from "@chakra-ui/react";

import userRoles from "../common/userRoles";
import { UserStatusBadge, ticketStatuses } from "../common/TicketStatus";


import UserBox from "../common/UserBox";
import { CardWrapper } from "../common/Card";
import { SavingButton } from "../common/SavingStatusBox";
import useInterval from "../common/useInterval";

function UserDetailsCard({ selectedUsers, allUsers }) {
    const { userCred } = useContext(UserContext);
    const singleUser = allUsers.find((u) => u.id === selectedUsers[0]);
    const handleDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ${singleUser.name}?`)) {
            const res = await deleteUser(singleUser.id, { userId: userCred.id, key: userCred.key });
            // reload page
            if (res === "ok") {
                window.location.reload();
            }
        }
    }

    return (
        <CardWrapper>
            {selectedUsers.length === 1 ? (
                <>

                    <Text fontSize="md" fontWeight="bold">{singleUser.name}</Text>
                    <UserStatusBadge status={singleUser.status} isOutlined />

                    <Feature title="RegId" content={singleUser.regId} />
                    <Feature title="Postcode" content={singleUser.postcode} />
                    <Feature title="hasPaid" content={singleUser.hasPaid ? "true" : "false"} />
                    {singleUser.buyer && (
                        <Feature title="Buyer" content={singleUser.buyer.name} />
                    )}
                    {singleUser.partners.length > 0 && (
                        <Feature
                            title="Partners"
                            content={singleUser.partners.map((p) => p.name).join(", ")}
                        />
                    )}
                    <Button colorScheme="red" onClick={handleDelete}>
                        Delete
                    </Button>

                </>


            ) : (
                <Text>Select a single user </Text>
            )}
        </CardWrapper>
    );
}

const fetchIntervals = [
    { value: 0, label: "Never" },
    { value: 2000, label: "2 sec" },
    { value: 30000, label: "30 sec" },
    { value: 120000, label: "2 mins" },
]

export function AdminPanel({ navigate }) {
    const { user } = useContext(UserContext);
    const [allUsers, setAllUsers] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [newStatus, setNewStatus] = useState(ticketStatuses.WANTING);
    const [interval, setInterval] = useState(fetchIntervals[2].value);

    const loading = !allUsers;

    function onSelectChange(id) {
        if (selectedUsers.includes(id)) {
            setSelectedUsers(selectedUsers.filter((item) => item !== id));
        } else {
            setSelectedUsers([...selectedUsers, id]);
        }
    }
    const fetchData = useCallback(async () => {
        if (isFetching) {
            return;
        }
        setIsFetching(true);
        const newAllUsers = await findUsers(user.id, user.key, "*", "admin");
        if (!newAllUsers) {
            return;
        }

        // sort by user.name
        newAllUsers.sort((a, b) => a.name.localeCompare(b.name));
        newAllUsers.forEach((user) => {
            user.isBuyer = false;
        });
        newAllUsers.forEach((user) => {
            if (user.status === ticketStatuses.LOCKED || user.status === ticketStatuses.PURCHASED || user.status === ticketStatuses.CONFIRMED) {
                user.buyer = newAllUsers.find((u) => {
                    if (u.id === user.buyerId) {
                        u.isBuyer = true;
                        return true;
                    }
                    return false;
                });
            }
        });
        newAllUsers.forEach((user) => {
            user.partnerIds = user.partners;
            user.partners = [];
            user.partnerIds.forEach((partnerId) => {
                user.partners.push(newAllUsers.find((u) => u.id === partnerId));
            });
        });
        console.log("Admin: Fetched all users.");
        setAllUsers(newAllUsers);
        setIsFetching(false);
    }, [user.id, user.key, isFetching]);

    useEffect(() => {

        if (loading) {
            fetchData();
        }
    }, [
        loading,
        fetchData,
    ]);

    useInterval(() => {
        if (interval > 0) {
            fetchData();
        }
    }, interval > 0 ? interval : null);

    async function saveStatus() {
        var success = true;
        const newAllUsers = [...allUsers];
        for (const id of selectedUsers) {
            // find user in all users list
            const selectedUser = newAllUsers.find((u) => u.id === id);
            if (
                (await updateUserStatusAdmin(id, { userId: user.id, key: user.key },
                    selectedUser.status, newStatus)) === "ok"
            ) {
                selectedUser.status = newStatus;
            }
            else {
                success = false;
            }
        }
        setAllUsers(newAllUsers);
        return success;
    }



    return (
        user.role === userRoles.ADMIN &&
        <Flex
            minH="60vh"
            justify="center"
            align="center"
            direction="column"
            textAlign="center"
            wrap="wrap"
        >
            <Heading>Admin Panel</Heading>
            {loading ? (<Flex py={10} justify="center" align="center">
                <Spinner mx="auto" size="xl" />
            </Flex>) : (
                <Flex justify="center" wrap="wrap">
                    <Box m="4" p="4" maxW="xl">
                        <Stack spacing={8}>
                            <CardWrapper>
                                {allUsers.map((user) => (
                                    <User key={user.id} user={user} allUsers={allUsers} selected={selectedUsers.includes(user.id)} onChange={onSelectChange} />
                                ))
                                }
                            </CardWrapper>
                        </Stack>

                    </Box>
                    <Box m="4" p="4" maxW="xl">
                        <Stack spacing={8}>
                            <CardWrapper>
                                {allUsers.filter((user) => selectedUsers.includes(user.id)).map((user) => (
                                    <User key={user.id} user={user} selected={selectedUsers.includes(user.id)} onChange={onSelectChange} />
                                ))}
                                <Select
                                    my="2"
                                    onChange={(e) => setNewStatus(e.target.value)}
                                    value={newStatus}
                                >
                                    <option value={ticketStatuses.WANTING}>WANTING</option>
                                    <option value={ticketStatuses.PURCHASED}>PURCHASED</option>
                                    <option value={ticketStatuses.PURCHASED}>CONFIRMED</option>
                                    <option value={ticketStatuses.NOT_WANTING}>NOT_WANTING</option>
                                    <option value={ticketStatuses.HAS_TICKET}>HAS_TICKET</option>
                                </Select>
                                <SavingButton saveCb={saveStatus}
                                    isEditing={true}
                                    setIsEditing={undefined}
                                />
                            </CardWrapper>
                            <UserDetailsCard selectedUsers={selectedUsers} allUsers={allUsers} />

                            <CardWrapper>
                                <Heading size="md">Auto-Update Interval</Heading>
                                <Stack direction="row" justify="space-between" align="end">
                                    <RadioGroup
                                        name="updateInterval"
                                        value={interval.toString()}
                                        defaultValue={interval.toString()}
                                        onChange={(value) => setInterval(Number(value))}
                                    >
                                        <Stack direction="row">
                                            {fetchIntervals.map((intervalOption) => (
                                                <Radio key={intervalOption.value} value={intervalOption.value.toString()}>
                                                    {intervalOption.label}
                                                </Radio>
                                            ))}
                                        </Stack>
                                    </RadioGroup>
                                    <Spinner size="sm" display={isFetching ? "block" : "none"} />
                                </Stack>
                            </CardWrapper>

                        </Stack>
                    </Box>

                    <Box m="4" p="4" maxW="xl">
                        <Stack spacing={8}>

                        </Stack>
                    </Box>

                </Flex>

            )}
        </Flex>
    );
}

function Feature({ title, content }) {
    return (
        <Flex wrap="wrap">
            <Text fontSize="md" fontWeight="bold">
                {title}:
            </Text>
            <Text ml="2" fontSize="md">
                {content}
            </Text>
        </Flex>
    );
}

function User({ user, allUsers, selected, onChange }) {

    return (
        <Popover trigger="hover" placement="right">
            <PopoverTrigger>

                <Box
                    as="div"
                    display="flex"
                    alignItems="center"
                    height={8}
                    borderBottomWidth="1px"
                    borderBottomStyle="solid"
                    borderBottomColor="grey.500"
                    bg={user.isBuyer ? "orange.100" : "white"}
                    px="2"
                    role="group"
                >
                    <Checkbox value={user.id} isChecked={selected} onChange={(e) => {

                        onChange(user.id);
                    }} >
                        <UserBox user={user} showBadge={false} hideIcon={true} />
                    </Checkbox>
                    {user.isBuyer && (
                        <Box ml="auto">
                            💵

                        </Box>
                    )}
                    <Box alignSelf="flex-end" ml="auto">

                        <UserStatusBadge status={user.status} isOutlined />
                    </Box>

                </Box>
            </PopoverTrigger>
            <PopoverContent zIndex={4}>


                <PopoverBody>



                    {user.buyer && (
                        <Feature title="Buyer" content={user.buyer.name} />
                    )}


                </PopoverBody>
            </PopoverContent>
        </Popover>

    );
}