import React from "react";
import { Flex, Heading, Box, Image, Text, Divider } from "@chakra-ui/react";
import dashboardPic from "./puddle-dashboard.svg";
import detailsPic from "./puddle-details.svg";
import confirmPic from "./puddle-confirm.svg";

function Card({ children }) {
  return (
    <Box
      m="3"
      p={5}
      minW="56"
      w={["90vw", "70vw", "30vw"]}
      shadow="lg"
      borderWidth="2px"
      rounded="lg"
    >
      {children}
    </Box>
  );
}

function TextBox({ children }) {
  return <Text mt="4">{children}</Text>;
}

export function ProcessArticle() {
  return (
    <Flex direction="column" align="center" as="article" mx="1" my="6">
      <Divider w="70vw" />
      <Heading mt="12" mb="5" as="h2">
        How it works
      </Heading>
      <Flex direction={["column", "column", "row"]}>
        <Card>
          <NumberedHeader n="1" title="Pick Friends" />
          <Image
            w="100%"
            maxW="sm"
            mx="auto"
            src={dashboardPic}
            alt="screenshot of the puddle dashboard"
          />
          <TextBox>
            Join the <em>puddle</em> with your event registration details.{" "}
            <br /> Put your friends in you priority list. These are the people
            you want to buy tickets for if you get the chance. You may also
            select <em>Partners</em> who are people you don't want to go
            without.
          </TextBox>
        </Card>
        <Card>
          <NumberedHeader n="2" title="Buy Tickets" />
          <Image
            w="100%"
            maxW="sm"
            mx="auto"
            src={detailsPic}
            alt="screenshot of the copy details page"
          />
          <TextBox>
            Try to buy tickets. If you manage to get through, PuddleTix will
            show you the people from your list who are still in need of a
            ticket. You can copy their details to the event page, pay for their
            tickets, and then try again for more.
          </TextBox>
        </Card>
        <Card>
          <NumberedHeader n="3" title="Confirm" />
          <Image
            w="100%"
            maxW="sm"
            mx="auto"
            src={confirmPic}
            alt="screenshot with large tick"
          />
          <TextBox>
            Once all the tickets have sold out you wait for the confirmation
            email from the ticketing site. Then mark those who you have bought
            tickets for as confirmed. After they pay you back, you can mark them
            as having paid you.
          </TextBox>
        </Card>
      </Flex>
    </Flex>
  );
}
function NumberedHeader({ n, title }) {
  return (
    <Flex align="baseline" justify="center" pb="4">
      <Text mr="3" fontSize="2xl" fontWeight="bold">
        {n}:
      </Text>{" "}
      <Text fontSize="xl">{title}</Text>
    </Flex>
  );
}
