import React, { useState, useEffect } from "react";
import { Flex, Text, Spinner, Button } from "@chakra-ui/react";
import { CheckIcon, WarningIcon } from "@chakra-ui/icons";

export function SavingStatusBox({ status }) {
  let text, icon;
  let display = "flex";
  switch (status) {
    case "saving":
      text = <Text color="gray.500">Saving...</Text>;
      icon = <Spinner color="gray.500" ml="3" size="sm" />;
      break;
    case "unsaved":
      text = <Text color="gray.400">Unsaved changes. Wait.</Text>;
      icon = <WarningIcon ml="3" color="gray.300" />;
      break;
    case "saved":
      text = <Text color="green.400">Saved</Text>;
      icon = <CheckIcon ml="3" color="green.400" />;
      break;

    case "error":
      text = <Text color="red.500">Failed to save</Text>;
      icon = <WarningIcon ml="3" color="red.500" />;
      break;
    case "none":
      display = "none";
      break;
    default:
      text = " ";
      icon = " ";
  }

  return (
    <Flex
      display={display}
      h="6"
      mt="1"
      pr="2"
      align="center"
      justify="flex-end"
    >
      {text}
      {icon}
    </Flex>
  );
}

export function SavingButton({ saveCb, isEditing, setIsEditing }) {
  const [popupTimer, setPopupTimer] = useState(0);
  const [editTimer, setEditTimer] = useState(0);
  const [status, setStatus] = useState("unsaved");

  function finishEdit() {
    if (setIsEditing) {
      setIsEditing(false);
      setPopupTimer(setTimeout(() => setStatus("done"), 5000));
    }
    else {
      setPopupTimer(setTimeout(() => setStatus("unsaved"), 3000));
    }
  }

  function onBtnClick() {
    async function save() {
      if (await saveCb()) {
        setStatus("saved");

        setEditTimer(setTimeout(finishEdit, 30));
      } else {
        setStatus("error");
      }
    }
    setStatus("saving");
    save();
  }

  useEffect(() => {
    return () => {
      clearTimeout(popupTimer);
      clearTimeout(editTimer);
    };
  }, [popupTimer, editTimer]);

  const sh = isEditing || status === "saved";

  //console.log(status, isEditing, "=>", sh);

  return (
    <Flex direction="column" alignItems="flex-end">
      {sh && ["unsaved", "saving", "error", "done"].includes(status) && (
        <Button
          mt="2"
          colorScheme="blue"
          aria-label="save"
          onClick={onBtnClick}
          isLoading={status === "saving"}
          loadingText="Saving"
        >
          Save
        </Button>
      )}
      {sh && ["saved", "error"].includes(status) && (
        <SavingStatusBox status={status} />
      )}
    </Flex>
  );
}
