import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./theme";
import "./App.css";
import { Router } from "@reach/router";

import { UserContext, UserProvider } from "./context";

import { getUser, findUsers } from "./api";

import Header from "./common/Header";
import Banner from "./common/Banner";
import Footer from "./common/Footer";
import SignUp from "./pages/SignUp";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./dashboard/Dashboard";
import BookingPages from "./booking/BookingPages";
import SharedDetailsPage from "./shared-details/SharedDetailsPage";
import HomePage from "./home/HomePage";

import { ticketStatuses } from "./common/TicketStatus";
import { AdminPanel } from "./pages/AdminPanel";
import useInterval from "./common/useInterval";


const PrivateRoute = (props) => {
  const { user } = useContext(UserContext);

  let { as: Comp, ...restOfTheProps } = props;
  return user ? <Comp {...restOfTheProps} /> : <LoginPage />;
};

function App() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userCred")) || null);
  const hasFetchedUser = useRef(false);
  const [lastFullFetchTime, setLastFullFetchTime] = useState(Date.now());
  const [shouldFetchFullUser, setShouldFetchFullUser] = useState(false);

  const [allUsers, setAllUsers] = useState([]);




  const [userCred, setUserCred] = useState(
    JSON.parse(localStorage.getItem("userCred")) || null);

  const expandUser = (newUser) => {
    if (!newUser) {
      return null;
    }

    if (newUser.shareId === "deactivated") {
      newUser.shareId = null;
    }
    if (!newUser.buyer) {
      newUser.buyer = {};
    }
    if (!newUser.boughtFor) {
      newUser.boughtFor = [];
    }
    newUser.hasBoughtForLocked = false; // Flag set if the user has a lock on anybody
    for (const u of newUser.boughtFor) {
      if (u.status === ticketStatuses.LOCKED) {
        newUser.hasBoughtForLocked = true;
        break;
      }
    }
    return newUser;
  };

  const updateUser = useCallback(
    (updatedUser) => {

      if (!updatedUser) {
        console.error("No user to update");
        return;
      }

      let newUser = updatedUser;
      if (user) {
        newUser = { ...user, ...updatedUser }; //merge together
      }
      newUser = expandUser(newUser);

      setUser(newUser);
    },
    [user]
  );

  const loginNewUser = useCallback(
    (newUser) => {
      if (newUser) {
        updateUser(newUser);
        if (!userCred || !userCred.id || !userCred.key || (newUser.id !== userCred.id || newUser.key !== userCred.key)) {
          let newUserCred = { id: newUser.id, key: newUser.key, name: "-", postcode: "-", regId: "-", rankings: [], coaches: [] };
          newUserCred = expandUser(newUserCred);
          localStorage.setItem("userCred", JSON.stringify(newUserCred));
          console.log("User cred saved");
          setUserCred(newUserCred);
        }
      }
      else {
        console.error("No user to login");
      }
    },
    [updateUser, userCred, setUserCred]
  );

  const logoutUser = useCallback(() => {
    setUser(null);
    setUserCred(null);
    localStorage.removeItem("userCred");
    console.log("User cred removed");
  },
    [setUser, setUserCred]);


  const fetchAllUsers = useCallback(async () => {
    if (!userCred || !userCred.id || !userCred.key) {
      return;
    }
    const newAllUsers = await findUsers(userCred.id, userCred.key);
    setAllUsers(newAllUsers);
    // update current user status from allUsers

  }, [userCred]);


  const fetchFullUser = useCallback(async () => {
    if (!userCred || !userCred.id || !userCred.key) {
      return;
    }

    const newUser = await getUser(userCred.id, userCred.key);
    if (newUser) {
      expandUser(newUser);
      setUser(newUser);
      setLastFullFetchTime(Date.now());
    }


  }, [userCred]);


  const fullUserIntervalSeconds = 5;
  useInterval(() => {


    // if time since last full fetch is more than 5 seconds, fetch full user
    if (Date.now() - lastFullFetchTime > fullUserIntervalSeconds * 1000) {

      setShouldFetchFullUser(true);
    }

  }, fullUserIntervalSeconds * 1000);

  // Separate effect handles the actual fetching
  useEffect(() => {
    if (shouldFetchFullUser) {
      fetchFullUser();
      fetchAllUsers();
      setShouldFetchFullUser(false);
    }
  }, [shouldFetchFullUser, fetchFullUser, fetchAllUsers]);

  useEffect(() => {


    if (!hasFetchedUser.current) {
      console.log("First time fetch");
      fetchFullUser();
      fetchAllUsers();
      hasFetchedUser.current = true;
    }

  }, [hasFetchedUser, fetchFullUser, fetchAllUsers]);

  return (
    <ChakraProvider theme={customTheme}>
      <UserProvider value={{ user, userCred, updateUser, fetchFullUser, loginNewUser, logoutUser, allUsers }}>
        <Banner />
        <Header />
        <main>
          <Router>
            <NotFound default />
            <HomePage path="/" />
            <SignUp path="/join" preFill="none" />
            <SignUp path="/join/:preFill" />
            <SharedDetailsPage path="/details/:shareId" />
            <PrivateRoute as={Dashboard} path="/dashboard" />
            <PrivateRoute as={BookingPages} path="/book" />
            <PrivateRoute as={AdminPanel} path="/admin" />

          </Router>
        </main>
        <Footer />
      </UserProvider>
    </ChakraProvider>
  );
}

const NotFound = () => <p>Sorry, nothing here</p>;

export default App;
