import React from "react";
import { Flex, Heading, Text, Divider, Box, Button, Link } from "@chakra-ui/react";
import { Link as ReachLink } from "@reach/router";

function TryItSandbox() {
  return (
    <Flex direction="column" align="center" as="article" mx="1" my="5">
      <Divider w="70vw" />
      <Heading my="8" as="h2">
        Beta? Try It!
      </Heading>
      <Box as="section" maxW="2xl">
        <Text fontWeight="bold">PuddleTix is just starting out...</Text>
        <Text pl="4">
          You are in the "Sandbox" with plenty of randomly generated
          users. You can try it out now by registering with some random details
          yourself. Just click below.
        </Text>
      </Box>
      <Button
        m="10"
        colorScheme="blue"
        as={ReachLink}
        size="lg"
        to="/join/random"
      >
        Try It Now!
      </Button>
    </Flex>
  );
}

function TryItNormal() {
  return (
    <Flex direction="column" align="center" as="article" mx="1" my="5">
      <Divider w="70vw" />
      <Heading my="8" as="h2">
        Beta? Try It!
      </Heading>
      <Box as="section" maxW="2xl">
        <Text fontWeight="bold">PuddleTix is just starting out...</Text>
        <Text pl="4">
          Only a single puddle exists at the moment which is set up for the
          ticketing system used by the Glastonbury Festival website. Sign up is open now.
          You can also try it out in the "Sandbox" which is
          like a play area to see how it works. Just click below.
        </Text>
      </Box>
      <Button
        m="10"
        colorScheme="blue"
        as={Link}
        size="lg"
        href='https://sandbox.puddletix.com/join/random'
      >
        Try it in the Sandbox
      </Button>
    </Flex>
  );
}


export function TryItArticle() {
  return (
    process.env.REACT_APP_ENV === 'sandbox' ? <TryItSandbox /> : <TryItNormal />
  );
}
