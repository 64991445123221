import axios from "axios";
import { ticketStatuses } from "./common/TicketStatus";

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

let currentUser = {};

const endpoint = process.env.REACT_APP_API_URL;

export async function getCoachOptions() {
  try {
    const response = await axios.get(endpoint + "/info");
    return ["ok", response.data];
  } catch (error) {
    if (error.response && error.response.data) {
      return [error.response.data.code];
    }
    return [undefined];
  }
}

export async function createUser(formData) {
  const body = {
    usrName: formData.name,
    regId: formData.regId,
    postcode: formData.postcode,
  };
  if (formData.email !== "") {
    body.email = formData.email;
  }
  try {
    const response = await axios.post(endpoint + "/user", body);
    currentUser = response.data.user;
    return ["ok", currentUser];
  } catch (error) {
    if (error.response && error.response.data) {
      return [error.response.data.code];
    }
    return [undefined];
  }
}

export async function loginUser(regId) {
  try {
    const response = await axios.get(endpoint + "/login", {
      params: { regId },
    });
    currentUser = response.data.user;
    return ["ok", currentUser];
  } catch (error) {
    if (error.response && error.response.data) {
      return [error.response.data.code];
    }
    return [undefined];
  }
}

export async function updateUserDetails(cred, details) {
  const body = {
    id: cred.userId,
    key: cred.key,
    name: details.name,
    regId: details.regId,
    postcode: details.postcode,
  };
  try {
    const response = await axios.patch(endpoint + "/user", body);
    currentUser = response.data.user;
    if (currentUser.id) return ["ok", currentUser];
  } catch (error) {
    console.log(error);
    if (error.response && error.response.data) {
      return [error.response.data.code, error.response.data.det_message];
    }
    return [undefined];
  }
}

// Ask API to generate a new share Id
export async function generateShareId(userId, key) {
  const body = {
    id: userId,
    key: key,
    shareId: "", // This indicates to the api that we want a new share Id
  };
  try {
    const response = await axios.patch(endpoint + "/user", body);
    currentUser = response.data.user;
    if (currentUser.shareId !== "") return ["ok", currentUser.shareId];
  } catch (error) {
    if (error.response && error.response.data) {
      return [error.response.data.code];
    }
    return [undefined];
  }
}

function markSelf(userId, userArr) {
  userArr.forEach((user) => {
    if (user.id === userId) {
      user.self = true;
    }
  });
  return userArr;
}

export async function getRankings(userId, key) {
  try {
    const response = await axios.get(endpoint + "/rankings", {
      params: { userId, key, limit: 30 },
    });

    const rankings = response.data.rankings;

    markSelf(userId, rankings.manual);
    markSelf(userId, rankings.all);

    return ["ok", rankings];
  } catch (error) {
    if (error.response && error.response.data) {
      return [error.response.data.code];
    }
    return [undefined];
  }
}

export async function setRankings(userId, key, rankings) {
  const body = {
    id: userId,
    key: key,
    rankings: rankings,
  };

  try {
    const response = await axios.patch(endpoint + "/user", body);
    currentUser = response.data.user;
    return "ok";
  } catch (error) {
    return null;
  }
}

export async function setCoaches(userId, key, coaches) {
  const body = {
    id: userId,
    key: key,
    coaches: coaches,
  };

  try {
    const response = await axios.patch(endpoint + "/user", body);
    currentUser = response.data.user;
    return currentUser;
  } catch (error) {
    return null;
  }
}

export async function setPartners(userId, key, partners) {
  const body = {
    id: userId,
    key: key,
    partners: partners,
  };

  try {
    const response = await axios.patch(endpoint + "/user", body);
    currentUser = response.data.user;
    return currentUser;
  } catch (error) {
    return null;
  }
}

export async function updateUserStatus(cred, status) {
  status = parseInt(status);
  const body = {
    id: cred.userId,
    key: cred.key,
    status: status,
  };

  try {
    const response = await axios.patch(endpoint + "/user", body);
    return response.data.user.status === status ? "ok" : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updateUserStatusAdmin(userId, cred, oldStatus, newStatus) {
  oldStatus = parseInt(oldStatus);
  newStatus = parseInt(newStatus);
  const body = {
    mode: "admin-status",
    id: userId,
    idAdmin: cred.userId,
    key: cred.key,
    status: newStatus,
    oldStatus: oldStatus,
  };

  try {
    const response = await axios.patch(endpoint + "/user", body);
    return response.data.user.status === newStatus ? "ok" : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function deleteUser(userId, cred) {

  const body = {

    id: userId,
    idRequestor: cred.userId,
    key: cred.key,
  };

  try {
    const response = await axios.patch(endpoint + "/deleteuser", body);
    return response.data.user.id === userId ? "ok" : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}



export async function confirmPurchase(userId, key, list) {
  const boughtFor = [];
  for (const item of list) {
    boughtFor.push({
      id: item.id,
      status:
        item.purchased === "y"
          ? ticketStatuses.PURCHASED
          : ticketStatuses.WANTING,
    });
  }

  const body = { id: userId, key, boughtFor };

  try {
    const response = await axios.patch(endpoint + "/users", body);
    const partial_success = response.data.results.reduce(
      (bool, res) => bool || res.success,
      false
    );

    const success = response.data.results.reduce(
      (bool, res) => bool && res.success,
      true
    );

    // return ok as long as one update succeeded. Not worth bothering users with partial success.
    if (!success) {
      console.error("Not a full confirmation success");
    }

    return partial_success ? "ok" : "error"; // should be "ok"
  } catch (error) {
    console.log(error);
    return "error";
  }
}

export async function getDetails(userId, key) {
  try {
    const response = await axios.get(endpoint + "/rankings", {
      params: { userId, key, limit: 6, mode: "combined-lock" },
    });

    const rankings = response.data.rankings;

    markSelf(userId, rankings);

    return ["ok", { commonCoaches: response.data.commonCoaches, rankings: rankings }];
  } catch (error) {
    if (error.response && error.response.data) {
      return [error.response.data.code];
    }
    return [undefined];
  }
}

export async function getUser(userId, key) {
  try {
    const response = await axios.get(endpoint + "/user", {
      params: { userId, key, mode: "filled" },
    });

    const boughtFor = response.data.user.boughtFor;
    markSelf(userId, boughtFor);

    currentUser = response.data.user;
    currentUser.boughtFor = boughtFor;

    return currentUser;
  } catch (error) {
    console.error(error);
    return false;
  }
}

// Should return a user and a generated shareKey if not given
export async function findPartialUserByShareId(shareId, shareKey = undefined) {
  try {
    const params = { shareId };
    if (shareKey) {
      params.reqUserId = shareKey;
    }

    const response = await axios.get(endpoint + "/users", {
      params,
    });

    console.log(response);

    if (!response.data.user.status) {
      throw Error("Returned user doesn't have a status");
    }
    if (!shareKey && !response.data.shareKey) {
      throw Error("Returned data doesn't have a shareKey");
    }
    return ["ok", response.data];
  } catch (error) {
    if (error.response && error.response.data) {
      return [error.response.data.code];
    }
    console.error(error);
    return [undefined];
  }
}

// obtains user details given the shareId
export async function getPartialUserByShareIdAndKey(
  shareUID,
  shareId,
  shareKey
) {
  try {
    const response = await axios.get(endpoint + "/user", {
      params: { mode: "partial", shareId, key: shareKey, userId: shareUID },
    });

    if (!response.data.user.status) {
      throw Error("Returned user doesn't have a status");
    }
    return ["ok", response.data.user];
  } catch (error) {
    if (error.response && error.response.data) {
      return [error.response.data.code];
    }
    console.error(error);
    return [undefined];
  }
}

// change status for a user that's been shared
export async function updateUserStatusUsingShareId(
  userID,
  shareId,
  shareKeyOrUserId,
  status
) {
  const body = {
    mode: "external-status",
    id: userID,
    shareId: shareId,
    key: shareKeyOrUserId, // could also be a logged in user's id
    status: status,
  };

  try {
    const response = await axios.patch(endpoint + "/user", body);

    if (
      response.data.user.status !== status ||
      response.data.user.buyerId !== shareKeyOrUserId
    ) {
      return ["failed", response.data.user];
    }
    return ["ok", response.data.user];
  } catch (error) {
    if (error.response && error.response.data) {
      return [error.response.data.code];
    }
    console.error(error);
    return [undefined];
  }
}

export async function confirmTicket(userId, key, list) {
  const boughtFor = [];
  for (const item of list) {
    boughtFor.push({
      id: item.id,
      hasPaid: item.hasPaid,
      status: item.willDelete ? ticketStatuses.WANTING : item.status,
    });
  }

  const body = { id: userId, key, boughtFor };

  try {
    const response = await axios.patch(endpoint + "/users", body);
    return response.data.results;
  } catch (error) {
    console.log(error);
    return "error";
  }
}

// Find User function
export async function findUsers(userId, key, query = "*", mode = "partial") {
  try {
    const response = await axios.get(endpoint + "/users", {
      params: { reqUserId: userId, key, q: query, mode },
    });
    if (!Array.isArray(response.data.users)) {
      throw Error("No users array returned.");
    }
    return response.data.users;
  } catch (error) {
    console.error(error);
    return false;
  }
}
