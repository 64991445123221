import React, { useContext } from "react";
import {
  Heading,
  Flex,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link as chakraLink,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { UserStatusCircle } from "./TicketStatus";

import { navigate, Link, Location } from "@reach/router";

import { UserContext } from "../context";

import { PuddleLogoIcon } from "../theme";

function Header() {
  const { user, logoutUser } = useContext(UserContext);
  function logOut() {
    logoutUser();
    navigate("/");
  }

  // function InOrOut(status) {
  //   switch (status) {
  //     case ticketStatuses.WANTING:
  //       return true;
  //     case ticketStatuses.LOCKED:
  //     case ticketStatuses.PURCHASED:
  //     case ticketStatuses.CONFIRMED:
  //     case ticketStatuses.HAS_TICKET:
  //     case ticketStatuses.NOT_WANTING:
  //       return false;
  //   }
  // }

  // function onInOutChange(e) {
  //   let newStatus;
  //   if (e.target.checked) {
  //     newStatus = ticketStatuses.WANTING;
  //   } else {
  //     newStatus = ticketStatuses.NOT_WANTING;
  //   }
  //   updateUser({ ...user, status: newStatus });
  // }

  return (
    <Flex
      as="header"
      justify="space-between"
      padding={{ base: "3", md: "6" }}
      bg="blue.600"
      align="center"
      color="white"
    >
      <Box display={{ base: "none", md: "block" }} flex="1" />

      <Location>
        {({ location }) => {
          if (location.pathname === "/") {
            return null;
          }
          return (
            <>
              <Link to="/">
                <PuddleLogoIcon boxSize="3em" mx="3" />
              </Link>
              <Link to="/">
                <Heading
                  d={{ base: "none", md: "block" }}
                  as="h1"
                  size="lg"
                  letterSpacing={"-.1rem"}
                >
                  puddleTix
                </Heading>
              </Link>
            </>
          );
        }}
      </Location>

      <Flex align="center" justifyContent="flex-end" flex="1">
        {user ? (
          <>
            {/* <Tag mr={2}>
              {InOrOut(user.status) ? "In" : "Out of"} puddle{" "}
              <Switch
                size="sm"
                isChecked={InOrOut(user.status)}
                ml={2}
                onChange={(e) => {
                  onInOutChange(e);
                }}
              />
            </Tag> */}

            <Box bg="white" rounded="4em" p="1" align="center">
              <UserStatusCircle status={user.status} isSolid />
            </Box>

            <Menu>
              <MenuButton
                as={Button}
                variant="ghost"
                colorScheme="blue"
                color="white"
                rightIcon={< ChevronDownIcon />}
                _hover={{ bg: "blue.700" }}
                _active={{
                  bg: "blue.800",
                }}
              >
                {user.name}
              </MenuButton>
              <MenuList color="black">
                <MenuItem onClick={() => navigate("/dashboard")}>
                  Dashboard
                </MenuItem>
                <MenuItem onClick={logOut}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </>
        ) : (
          <>
            <Button
              bg="transparent"
              border="1px"
              onClick={() => navigate("/dashboard")}
            >
              Sign in
            </Button>

            <Button
              ml="3"
              bg="transparent"
              border="1px"
              onClick={() => navigate("/join")}
              isDisabled={process.env.REACT_APP_ENV === 'none'}
            >
              {process.env.REACT_APP_ENV === 'sandbox' ? "Join Sandbox" : "Join Puddle"}
            </Button>

            {
              process.env.REACT_APP_ENV === 'production' &&
              <Button
                as={chakraLink}
                ml="3"
                bg="transparent"
                border="1px"
                href="http://sandbox.puddletix.com/"
              >
                Sandbox
              </Button>

            }

          </>
        )}
      </Flex>
    </Flex>
  );
}

export default Header;

// import React from "react";
// import { Box, Heading, Flex, Text, Button } from "@chakra-ui/react";

// const MenuItems = ({ children }) => (
//   <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
//     {children}
//   </Text>
// );

// const Header = (props) => {
//   const [show, setShow] = React.useState(false);
//   const handleToggle = () => setShow(!show);

//   return (
//     <Flex
//       as="nav"
//       align="center"
//       justify="space-between"
//       wrap="wrap"
//       padding="1.5rem"
//       bg="teal.500"
//       color="white"
//       {...props}
//     >
//       <Flex align="center" mr={5}>
//         <Heading as="h1" size="lg" letterSpacing={"-.1rem"}>
//           Chakra UI
//         </Heading>
//       </Flex>

//       <Box display={{ sm: "block", md: "none" }} onClick={handleToggle}>
//         <svg
//           fill="white"
//           width="12px"
//           viewBox="0 0 20 20"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <title>Menu</title>
//           <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
//         </svg>
//       </Box>

//       <Box
//         display={{ sm: show ? "block" : "none", md: "flex" }}
//         width={{ sm: "full", md: "auto" }}
//         alignItems="center"
//         flexGrow={1}
//       >
//         <MenuItems>Docs</MenuItems>
//         <MenuItems>Examples</MenuItems>
//         <MenuItems>Blog</MenuItems>
//       </Box>

//       <Box
//         display={{ sm: show ? "block" : "none", md: "block" }}
//         mt={{ base: 4, md: 0 }}
//       >
//         <Button bg="transparent" border="1px">
//           Create account
//         </Button>
//       </Box>
//     </Flex>
//   );
// };

// export default Header;
