import React, { useState } from "react";


import {
  Box,
  Flex,
  Text,
  IconButton,
  Skeleton,
  useClipboard,
  Tooltip,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";

function DetailsEntry({ loading, text, isBlured = false, copyCb = null }) {
  const [copied, setCopied] = useState(false);

  const { onCopy } = useClipboard(text);

  function copyToClipboard() {
    onCopy();
    copiedText();
  }

  function copiedText() {
    setCopied(true);

    // call the callback the first time the field was copied
    if (copyCb && !copied) {
      copyCb();
      console.log("called callback");
    }
  }

  return (
    <Box
      as="td"
      p="1"
      bg={copied ? "blue.100" : "white"}
      borderWidth="1px"
      borderColor="gray.300"
    >
      <Skeleton isLoaded={!loading}>
        <Flex
          justify="space-between"
          align="center"
          css={isBlured && { filter: "blur(5px)" }}
          transition="filter 0.5s"
        >
          <Text
            fontFamily="mono"
            userSelect={isBlured ? "none" : "all"}
            mx="4"
            onCopy={copiedText}
          >
            {text}
          </Text>

          {document.queryCommandSupported("copy") && (
            <Tooltip hasArrow placement="right" label="Copy" showDelay="800">
              <IconButton
                isDisabled={isBlured}
                aria-label="Copy this value"
                icon={<CopyIcon />}
                variant="outline"
                onClick={copyToClipboard}
                size="sm"
                tabIndex="-1"
              />
            </Tooltip>
          )}
        </Flex>
      </Skeleton>
    </Box>
  );
}

// Renders the table showing the details
function CopyableDetailsTable({
  loading,
  list,
  isBlured = false,
  copyAllCb = null,
}) {
  let [numCopied, setNumCopied] = useState(0);

  // generates a list with fake data, useful for skeletons
  function createFakeList(n) {
    const u = {
      name: "Fake Name",
      regId: "Fake regId",
      postcode: "Fake postcode",
    };
    const list = [];
    for (let i = 0; i < n; i++) {
      list.push({ id: i, ...u });
    }
    return list;
  }

  if (loading) {
    list = createFakeList(6);
  }
  const listItems = list.map((u) => (
    <tr key={u.id}>
      <DetailsEntry
        text={u.name}
        loading={loading}
        isBlured={isBlured}
        copyCb={copyAllCb && incrementCopyCount}
      />
      <DetailsEntry
        text={u.regId}
        loading={loading}
        isBlured={isBlured}
        copyCb={copyAllCb && incrementCopyCount}
      />
      <DetailsEntry
        text={u.postcode}
        loading={loading}
        isBlured={isBlured}
        copyCb={copyAllCb && incrementCopyCount}
      />
    </tr>
  ));

  // Called by each entry when it has been copied for the first time
  function incrementCopyCount() {
    if (copyAllCb && numCopied + 1 >= 3 * list.length) {
      copyAllCb();
      setNumCopied(0);
    } else {
      setNumCopied(numCopied + 1);
    }
  }

  return (
    <Box as="table">
      <thead>
        <tr>
          <Box as="th">Name</Box>
          <Box as="th">Reg Number</Box>
          <Box as="th">Postcode</Box>
        </tr>
      </thead>
      <tbody>{listItems}</tbody>
    </Box>
  );
}

export default CopyableDetailsTable;
