import React, { useContext } from "react";
import {
  Box,
  Heading,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormControl,
  Input,
  Button,
} from "@chakra-ui/react";

import { loginUser } from "../api";

import { useForm } from "react-hook-form";
import { UserContext } from "../context";

function LoginForm() {
  const { loginNewUser } = useContext(UserContext);
  const {
    handleSubmit,
    errors,
    register,
    setError,
    clearError,
    formState,
  } = useForm();

  async function onSubmit(values) {
    const res = await loginUser(values.regId);
    switch (res[0]) {
      case "ok":
        console.log("logged in", res[1]);
        clearError("regId");
        loginNewUser(res[1]);
        break;
      case "REGID_NOT_FOUND":
        setError(
          "regId",
          "validate",
          "The specified registration id does not belong to any user."
        );
        break;
      default:
        console.log("Something unknown has gone wrong");
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isRequired isInvalid={errors.regId}>
        <FormLabel htmlFor="regId">Registration Id</FormLabel>
        <Input name="regId" placeholder="xxxxxxxxx" ref={register} />
        <FormErrorMessage>
          {errors.regId && errors.regId.message}
        </FormErrorMessage>
        <FormHelperText>
          The same regId as you signed up with. Yes, this isn't secure and I
          will change this in future.
        </FormHelperText>
      </FormControl>

      <Button
        mt={4}
        colorScheme="blue"
        type="submit"
        isLoading={formState.isSubmitting}
        loadingText="Logging in"
      >
        Sign in
      </Button>
    </form>
  );
}

function LoginPage(props) {
  return (
    <Box m="auto" maxW="xl" px="4">
      <Heading py={10}>Sign in</Heading>
      <LoginForm {...props} />
    </Box>
  );
}

export default LoginPage;
