import React from "react";

import { Box, Text, Radio, RadioGroup, Stack } from "@chakra-ui/react";

function ConfirmEntry({ children }) {
  return (
    <Box
      as="td"
      height="10"
      px="3"
      bg="white"
      borderWidth="1px"
      borderColor="gray.300"
    >
      {children}
    </Box>
  );
}

function ConfirmableDetailsTable({ list, setConfirmation, showErrors }) {
  const listItems = list.map((u) => {
    const isInvalid = showErrors && !u.purchased;

    return (
      <tr key={u.id}>
        <ConfirmEntry>
          <Text fontFamily="mono"> {u.name}</Text>
        </ConfirmEntry>

        <ConfirmEntry>
          <RadioGroup
            onChange={(val) => setConfirmation(u.id, val)}
          >
            <Stack direction="row">
              <Radio isInvalid={isInvalid} value="y">
                Y
              </Radio>
              <Radio isInvalid={isInvalid} value="n">
                N
              </Radio>
            </Stack>
          </RadioGroup>
        </ConfirmEntry>
      </tr>
    );
  });
  return (
    <Box as="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Purchased</th>
        </tr>
      </thead>
      <tbody>{listItems}</tbody>
    </Box>
  );
}

export default ConfirmableDetailsTable;
