import React, { useState, useEffect, useContext } from "react";

import {
  Flex,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  Switch,
  AlertDescription,
  FormLabel,
  Grid,
  Spinner,
  Text,
  Button,
  Tooltip,
} from "@chakra-ui/react";

import HelpText from "./HelpText";

import { updateUserStatusUsingShareId } from "../api";
import CopyableDetailsTable from "../booking/CopyableDetailsTable";
import { ticketStatuses } from "../common/TicketStatus";
import { useRef } from "react";
import { LockIcon } from "../theme";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { UserContext } from "../context";

export default function ExternalDetails({
  shownUser,
  shareId,
  shareKey,
  setShownUser,
  setAlert,
  shareUID,
}) {
  const statusInfo = statusSwitch(shownUser.status);

  // determines if the current user has the right to change the status of the shown user
  const hasEditRight =
    shownUser.status === ticketStatuses.WANTING ||
    (shownUser.buyerId === shareKey && statusInfo.isEditable);

  const [isLocked, setIsLocked] = useState(false); // status of buttons
  const [isBought, setIsBought] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const revealAndLockSwitch = useRef();

  const { user, updateUser } = useContext(UserContext);

  async function attemptStatusChange(status, attempt) {
    setAlert(null);

    const result = await updateUserStatusUsingShareId(
      shareUID,
      shareId,
      shareKey,
      status
    );

    switch (result[0]) {
      case "ok":
        const u = result[1];
        if (user) {
          const newUser = { ...user };
          newUser.status = status;
          updateUser(newUser);
        }
        setShownUser(u);
        // if (u.status === ticketStatuses.LOCKED) {
        //   setAlert([
        //     "success",
        //     "Locked",
        //     "You have locked this user. Now you can copy the details to the ticketing page.",
        //   ]);
        // }
        // if (u.status === ticketStatuses.PURCHASED) {
        //   setAlert([
        //     "success",
        //     "Bought",
        //     "You have marked yourself as having purchased a ticket using these details.",
        //   ]);
        // }
        setAlert(null);

        break;
      case "failed":
        console.log(`failed ${isUpdating}`, result[1]);

        setAlert([
          "error",
          `Failed to ${attempt}!`,
          "Maybe somebody else got there first.",
        ]);

        setShownUser(result[1]);
        break;

      default:
        setAlert(["error", "Failed!", "Check connection and try again."]);
        break;
    }
    setIsUpdating(false);
  }

  function onLockChange(e) {
    setIsLocked(e.target.checked);

    let newStatus = e.target.checked
      ? ticketStatuses.LOCKED
      : ticketStatuses.WANTING;
    setIsUpdating("Lock");
    setTimeout(() => {
      attemptStatusChange(newStatus, "Lock");
    }, 300);
  }
  function onBuyChange(e) {
    setIsBought(e.target.checked);
    let newStatus = e.target.checked
      ? ticketStatuses.PURCHASED
      : ticketStatuses.LOCKED;
    setIsUpdating("Buy");
    setTimeout(() => {
      attemptStatusChange(newStatus, "Buy");
    }, 300);
  }

  useEffect(() => {
    if (!isUpdating) {
      setIsLocked(statusInfo.isLocked);
      setIsBought(statusInfo.isBought);
    }
  }, [statusInfo, isUpdating]);

  return (
    <>
      <Heading mt="2" mb="6" color="gray.600">
        Details for {shownUser.name}
      </Heading>
      {!hasEditRight ? (
        statusInfo.alert
      ) : (
        <Text mb="8">
          {" "}
          {/* {shownUser.name} has shared their registration details with you!{" "}
          <br /> */}
          <Text as="strong" fontWeight="bold">
            Blurry?
          </Text>{" "}
          Click below on{" "}
          <Button
            variant="link"
            d="inline"
            verticalAlign="baseline"
            onClick={() => revealAndLockSwitch.current.focus()}
          >
            Reveal &amp; Lock
          </Button>{" "}
          to see the details. <br /> <em>Unlock</em> them again if you are not
          about to buy tickets.
        </Text>
      )}
      <CopyableDetailsTable
        list={[{ id: "1", ...shownUser }]}
        isBlured={!isLocked || isUpdating === "Lock"}
        copyAllCb={() => {
          setIsLocked(true);
        }}
      />

      <Grid
        justifyContent="end"
        mt="10"
        w="4xl"
        maxW="100vw"
        px="5"
        templateColumns={["auto", "1fr 2fr", "1fr 1.5fr"]}
        gap="3"
      >
        <Flex align="center" justify={["center", "flex-end"]}>
          {isUpdating === "Lock" && <Spinner size="md" mx="3" />}
          {hasEditRight && isUpdating !== "Lock" && isLocked && (
            <Tooltip
              hasArrow
              label="You have locked these details"
              placement="top"
            >
              <LockIcon
                color="orange.500"
                size="5"
                mx="2"
                d="block"
                position="relative"
                top="-3px"
              />
            </Tooltip>
          )}
          <FormLabel htmlFor="reveal-and-lock-details">
            Reveal &amp; Lock
          </FormLabel>
          <Switch
            id="reveal-and-lock-details"
            onChange={onLockChange}
            isChecked={isLocked}
            isDisabled={isUpdating || !hasEditRight || isBought}
            colorScheme="teal"
            size="lg"
            ref={revealAndLockSwitch}
          />{" "}
        </Flex>

        <Flex align="center">
          <HelpText titleTxt="Before copying the details ...">
            Click this when you are about to copy the above details into the
            ticketing page. This will alert others that these details are
            "locked" or "in use". If you are not buying tickets or get kicked
            out, unlock the details again so others can use them in case they
            get through.
          </HelpText>
        </Flex>
        <Flex align="center" justify={["center", "flex-end"]}>
          {isUpdating === "Buy" && <Spinner size="md" mx="3" />}
          {hasEditRight && isUpdating !== "Buy" && isBought && (
            <Tooltip
              hasArrow
              label="You have bought a ticket"
              placement="bottom"
            >
              <CheckCircleIcon
                color="green.600"
                name="check-circle"
                size="5"
                mx="2"
                d="block"
                position="relative"
                top="-3px"
              />
            </Tooltip>
          )}
          <FormLabel htmlFor="bought-tickets">Ticket Bought</FormLabel>
          <Switch
            id="bought-tickets"
            colorScheme="teal"
            size="lg"
            onChange={onBuyChange}
            isChecked={isBought}
            isDisabled={isUpdating || !hasEditRight}
          />
        </Flex>
        <Flex align="center">
          <HelpText titleTxt="After you bought a ticket ...">
            Once you have bought a ticket with the above details, mark them as
            "bought". This will let {shownUser.name} know that you have
            successfully bought a ticket using their details. It also "locks"
            the details, preventing others from attempting to also buy tickets
            for {shownUser.name}.
          </HelpText>
        </Flex>
      </Grid>
    </>
  );
}

function statusSwitch(status) {
  let alert = null; // potential alert
  let isLocked = false;
  let isBought = false;
  let isEditable = false; // true if user might be able to edit this status

  switch (status) {
    case ticketStatuses.WANTING:
      isEditable = true;
      break;
    case ticketStatuses.NOT_WANTING:
      isBought = true;
      isLocked = true;
      alert = (
        <Alert status="warning" mb="8">
          <AlertIcon />
          <AlertTitle mr={2}>Check First!</AlertTitle>
          <AlertDescription>
            This person has indicated that they do not want a ticket.
          </AlertDescription>
        </Alert>
      );
      break;
    case ticketStatuses.LOCKED:
      isEditable = true;

      isLocked = true;
      alert = (
        <Alert status="warning" mb="8">
          <AlertIcon />
          <AlertTitle mr={2}>This person is locked.</AlertTitle>
          <AlertDescription>
            Somebody else is currently buying them a ticket. Maybe best to wait
            a few minutes.
          </AlertDescription>
        </Alert>
      );
      break;

    default:
      isEditable = status === ticketStatuses.PURCHASED;
      isBought = true;
      isLocked = true;

      alert = (
        <Alert status="warning" mb="8">
          <AlertIcon />
          <AlertTitle mr={2}>This person already has a ticket.</AlertTitle>
          <AlertDescription>
            You should double check with them before trying to buy them one.
          </AlertDescription>
        </Alert>
      );

      break;
  }
  return { alert, isLocked, isBought, isEditable };
}
