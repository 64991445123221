import React, { useState } from "react";

import { Box, Button, Collapse } from "@chakra-ui/react";

function HelpText({ titleTxt, children, ...props }) {
  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);

  const button = (
    <Button
      ml="auto"
      verticalAlign="baseline"
      variant="link"
      onClick={handleToggle}
    >
      {show ? "Show Less" : "Help! Show More"}
    </Button>
  );

  return (
    <Box textAlign="left" {...props}>
      {!show && titleTxt} {!show && button}
      <Collapse in={show}>
        {children} <br />
        {show && button}
      </Collapse>
    </Box>
  );
}

export default HelpText;
