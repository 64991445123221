import React from "react";

import { Flex, Heading, Box, Text } from "@chakra-ui/react";

import banner from "./banner.jpg";
import { MotivationArticle } from "./MotivationArticle";
import { ProcessArticle } from "./ProcessArticle";
import { TryItArticle } from "./TryItArticle";
import { PuddleLogoIcon } from "../theme";

export default function HomePage() {
  return (
    <Flex direction="column" align="center">
      <Box
        as="header"
        backgroundImage={`url(${banner})`}
        w="100%"
        h="30vh"
        backgroundSize="cover"
        backgroundPosition="center"
        position="relative"
        mb={["2.5rem", "4.5rem"]}
      >
        {/* <img src={banner} alt="festival-with-flags" /> */}

        <Flex
          justify="center"
          w={["100%", "md", "lg"]}
          bg="blue.500" // "#05b0c1" "#7d363a"
          rounded={[0, "lg"]}
          height={["5rem", "6rem", "9rem"]}
          position="absolute"
          top="100%"
          left="50%"
          transform="translate(-50%,-50%)"
          color="white"
          align="center"
        >
          <PuddleLogoIcon boxSize={["3em", "4em", "6em"]} mr="1em" />

          <Heading as="h1" size="2xl" letterSpacing={"-.2rem"}>
            puddleTix
          </Heading>
          <Text
            color="red.500"
            fontFamily="Comic Sans MS, sans-serif"
            textShadow="1px 1px 5px darkgrey"
            textDecoration="underline"
            fontSize={["2em", "3em", "4em"]}
            fontWeight="bold"
            position="absolute"
            top="0"
            left="100%"
            transform={[
              "translate(-110%, -10%) rotate(10deg)",
              "translate(-80%,-20%) rotate(20deg)",
            ]}
          >
            {" "}
            Beta
            {" "}
          </Text>
        </Flex>
      </Box>
      <Heading as="h2" size="lg" mt="6" mb="4">
        Less Stress. More Tickets.
      </Heading>
      <MotivationArticle />
      <ProcessArticle />
      <TryItArticle />
    </Flex>
  );
}
