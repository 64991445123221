
import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context";

import { setPartners as apiSetPartners } from "../api";
import { EditableCard } from "../common/Card";
import {
    Box,
    Flex,
    Text,
    IconButton,
    Spinner,

} from "@chakra-ui/react";
import { SearchBox } from "./SearchBox";
import UserBox from "../common/UserBox";
import { SavingButton } from "../common/SavingStatusBox";
import { SmallCloseIcon, SmallAddIcon } from "@chakra-ui/icons";

function Partner({ user, handleClick, isPartner = false }) {
    let bg = "white";
    let height = 12;
    let icon = <SmallCloseIcon />;
    if (!isPartner) {
        bg = "gray.100";
        height = 8;
        icon = <SmallAddIcon />;
    }

    return (
        <Box
            as="div"
            display="flex"
            alignItems="center"
            height={height}
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="grey.500"
            bg={bg}
            px="2"
            role="group"
        >

            <UserBox user={user} />

            <Box
                ml="auto"
                as="div"
                display={isPartner ? "none" : "block"}
                _groupHover={{ display: "block" }}
            >
                <IconButton
                    variant="ghost"
                    colorScheme="blue"
                    aria-label="add to priority list"
                    icon={icon}
                    onClick={() => handleClick(user)}
                    height={height}
                    width={height}
                />
            </Box>
        </Box>
    );
}


function SelectedPartnersTable({ partners, onItemRemove }) {

    return (
        <Box>
            {partners.map((user) => (
                <Partner
                    key={user.id}
                    user={user}
                    handleClick={onItemRemove}
                    isPartner={true}
                />
            ))}
        </Box>
    );
}

function PotentialPartnersTable({ partners, onItemAdd }) {
    return (
        <Box maxHeight="200px" overflowY="auto">
            {partners.map((user) => (
                <Partner
                    key={user.id}
                    user={user}
                    handleClick={onItemAdd}
                    isPartner={false}
                />
            ))}
        </Box>

    );
}

export function Partners() {
    const { user, userCred, updateUser, allUsers } = useContext(UserContext);
    const [isEditing, setIsEditing] = useState(false);

    const [partners, setPartners] = useState(user.partners ?? []); // array of ids
    const [potPartners, setPotPartners] = useState([]); // array of ids
    const [searchFilter, setSearchFilter] = useState("");

    const loading = !allUsers;


    const onPartnerRemove = (partner) => {
        setPartners((partners) => partners.filter(partnerId => partnerId !== partner.id));
    };

    const onPartnerAdd = (partner) => {

        setPartners((partners) => partners.concat(partner.id));

    };

    async function onSave() {
        const savedUser = await apiSetPartners(user.id, user.key, partners);
        if (savedUser) {
            updateUser(savedUser);
            return true;
        } else {
            return false;
        }
    }

    const onSearchFilter = (newSearchFilter) => {
        setSearchFilter(newSearchFilter);
    };


    useEffect(() => {
        if (loading || !userCred) {
            return;
        }
        const filteredUsers =
            allUsers.filter((u) => {
                if (u.id === userCred.id) {
                    return false;
                }
                const name = new RegExp(searchFilter, "i").test(u.name);
                const regId = new RegExp(searchFilter, "i").test(u.regId);
                return (name || regId) && !partners.includes(u.id);
            })

        setPotPartners(filteredUsers);
    }, [
        searchFilter,
        allUsers,
        partners,
        loading,
        userCred,
    ]);


    useEffect(() => {
        if (!isEditing) {
            setPartners(user.partners ?? []);
        }
    }, [isEditing, user.partners]);

    function toggleEdit() {
        setPartners(user.partners ?? []);
        setIsEditing(!isEditing);
    }
    return (
        loading ? (
            <Flex py={10} justify="center" align="center">
                <Spinner mx="auto" size="xl" />
            </Flex>
        ) : (
            <EditableCard
                heading="Partners"
                onEditClick={toggleEdit}
                nextToButton={"🧑‍🤝‍🧑"}
                label="Edit your partners"
            >
                <Text color="gray.500" mt="3">
                    Select partners you do not want to go without. You will not be shown in a group of 6 without them,
                    unless they already have a ticket.
                </Text>
                {isEditing ? (

                    <>
                        <SelectedPartnersTable
                            partners={allUsers.filter(user => partners.includes(user.id))}
                            onItemRemove={onPartnerRemove}
                        />
                        <SearchBox onSearchFilter={onSearchFilter} />
                        <PotentialPartnersTable partners={potPartners} onItemAdd={onPartnerAdd} />
                    </>

                ) : (
                    <PersonalList partners={allUsers.filter(user => partners.includes(user.id))} />
                )}
                <SavingButton saveCb={onSave} isEditing={isEditing} setIsEditing={setIsEditing} />
            </EditableCard>
        )
    );
}

function PersonalList({ partners }) {

    const entries = partners.map(partner => (
        <Flex
            key={partner.id}
            align="center"
            py="1"
            borderBottomWidth="0px"
        >

            <UserBox user={partner} />
        </Flex>
    ))

    if (entries.length === 0) {
        // return <Box pt="2" >You have not selected any partners.</Box>
    }
    var text = "You have not selected any partners."
    if (entries.length > 0) {
        text = entries.length === 1 ? "You have 1 partner:" : `You have ${entries.length} partners:`
    }
    return (
        <Box>
            <Text pt="2" mb="5">{text}</Text>
            {entries}
        </Box>
    )
}
