import React from "react";

import { Flex, Text, Badge } from "@chakra-ui/react";

import { UserStatusCircle, UserStatusBadge } from "./TicketStatus";

export default function UserBox({ user, hideIcon = false, showBadge = false }) {
  return (
    <Flex align="center">
      {!hideIcon && <UserStatusCircle status={user.status} />}
      <Text mx="1">{user.name}</Text>
      <Text position="relative" top="1px" fontSize="xs" color="gray.500">
        ({user.regId})
      </Text>
      {user.self && (
        <Badge variant="outline" colorScheme="green" ml="4">
          YOU
        </Badge>
      )}

      {showBadge && <UserStatusBadge status={user.status} isOutlined />}
    </Flex>
  );
}
